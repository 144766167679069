<template>
  <div class="iq-card">
    <!-- Card header -->
    <div class="iq-card--header">
      <div class="md-title">{{ title }}</div>

      <md-button
        class="md-icon-button iq-card--header--button"
        @click="emitButtonClick"
        :style="hideButton ? 'display: none' : 'display: block'"
      >
        <md-icon>{{ buttonIcon }}</md-icon>
        <md-tooltip md-direction="left" v-if="buttonTooltip">{{
          buttonTooltip
        }}</md-tooltip>
      </md-button>
    </div>

    <!-- Card body -->
    <div class="iq-card--body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "iq-card",
  props: {
    hideButton: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    buttonIcon: {
      type: String,
      required: true,
    },
    buttonTooltip: String,
  },
  methods: {
    emitButtonClick() {
      this.$emit("buttonClick");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/constants";

.iq-card {
  border-radius: $border-radius;
  border: $border-default;
  background-color: white;

  &--header {
    border-bottom: $border-default;
    padding: $padding;
    display: flex;
    align-items: center;

    &--button {
      margin: 0 0 0 auto;
    }
  }
}
</style>
