import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    /**
     * Public routes
     * Accessible without login
     * !!! meta-property must contain -> public: true !!!
     **/
    {
      path: "/login",
      name: "Authorization",
      component: () => import("../views/public/Authorization"),
      meta: {
        public: true,
      },
    },
    {
      path: "/greeting",
      name: "Greeting",
      component: () => import("../views/public/Greeting"),
      meta: {
        public: true,
      },
    },
    {
      path: "/confirm/user",
      name: "Confirm User",
      component: () => import("../views/public/Confirmation"),
      meta: {
        public: true,
      },
    },
    {
      path: "/confirm/invite",
      name: "Confirm Invite",
      component: () => import("../views/public/ConfirmInvite"),
      meta: {
        public: true,
      },
    },
    {
      path: "/password/forgot",
      name: "Forgot Password",
      component: () => import("../views/public/ForgotPassword"),
      meta: {
        public: true,
      },
    },
    {
      path: "/password/reset",
      name: "Reset Password",
      component: () => import("../views/public/ResetPassword"),
      meta: {
        public: true,
      },
    },
    /**
     * Private routes
     * Not accessible without login
     * !!! meta-property must contain -> private: true !!!
     **/
    {
      path: "/",
      name: "Dashboard",
      component: () => import("../views/private/Dashboard"),
      meta: {
        private: true,
      },
    },
    {
      path: "/user",
      name: "Profile",
      component: () => import("../views/private/Profile"),
      meta: {
        private: true,
      },
    },
    {
      path: "/company",
      name: "Company",
      component: () => import("../views/private/Company"),
      meta: {
        private: true,
      },
    },
    {
      path: "/projects",
      name: "Projects",
      component: () => import("../views/private/Projects"),
      meta: {
        private: true,
      },
    },
    {
      path: "/project/:id",
      name: "Project",
      component: () => import("../views/private/Project"),
      meta: {
        private: true,
      },
    },
    {
      path: "/project/:id/users",
      name: "Project User Management",
      component: () => import("../views/private/ProjectUserManagement"),
      meta: {
        private: true,
      },
    },
    {
      path: "/project/:id/contacts",
      name: "Project Contacts",
      component: () => import("../views/private/ContactsNew"),
      meta: {
        private: true,
      },
    },
    {
      path: "/project/:id/contacts/:contact_id",
      name: "Contact",
      component: () => import("../views/private/Contact"),
      meta: {
        private: true,
      },
    },
    {
      path: "/project/:id/survey/create",
      name: "Create new survey",
      component: () => import("../views/private/SurveyBuilder"),
      meta: {
        private: true,
      },
    },
    // {
    //   path: "/test/:id/contacts",
    //   name: "Test",
    //   component: () => import("../views/private/ContactsNew"),
    //   meta: {
    //     private: true,
    //   },
    // },
    /**
     * Special routes
     * !!! wildcard for 404 -> * !!!
     **/
    {
      path: "/logout",
      name: "Logout",
      redirect: "/login",
      meta: {
        private: true,
      },
    },
    {
      path: "*",
      name: "404 Not found",
      component: () => import("../views/NotFound"),
    },
  ],
});
