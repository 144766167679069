import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user_is_logged_in: localStorage.getItem("access_token") || null,
    user_role: localStorage.getItem("user_role") || null,
    currentProject: null,
    lastProjectId: null,
    storedContacts: null,
    currentProjectDetail: null,
    currentProjectUsers: null,
    table: {
      offset: 0,
      filters: {
        status: "all",
        is_doctor: "all",
        label: "all",
        postal_code: "all",
        city: "all",
        specialty: "all",
        professional_group: "all",
        institution: "all",
      },
    },
    pagination: {
      pageIndex: 1,
      pageSize: 25,
    },
  },
  getters: {
    userIsLoggedIn(state) {
      return state.user_is_logged_in;
    },
    getUserRole(state) {
      return state.user_role;
    },
    getCurrentPageIndex(state) {
      return state.pagination.pageIndex;
    },
    getCurrentPageSize(state) {
      return state.pagination.pageSize;
    },
    getCurrentProject(state) {
      return state.currentProject;
    },
    getCurrentLastProjectId(state) {
      return state.lastProjectId;
    },
    getCurrentStoredContacts(state) {
      return state.storedContacts;
    },
    getCurrentProjectDetail(state) {
      return state.currentProjectDetail;
    },
    getCurrentProjectUsers(state) {
      return state.currentProjectUsers;
    },
    getCurrentOffset(state) {
      return state.table.offset;
    },
    getCurrentFilters(state) {
      return state.table.filters;
    },
  },
  mutations: {
    setUserIsLoggedIn(state, value) {
      state.user_is_logged_in = value;
    },
    setUserRole(state, value) {
      state.user_role = value;
    },
    setCurrentPageIndex(state, value) {
      state.pagination.pageIndex = value;
    },
    setCurrentPageSize(state, value) {
      state.pagination.pageSize = value;
    },
    setCurrentProject(state, value) {
      state.currentProject = value;
    },
    setCurrentLastProjectId(state, value) {
      state.lastProjectId = value;
    },
    setCurrentStoredContacts(state, value) {
      state.storedContacts = value;
    },
    setCurrentProjectDetail(state, value) {
      state.currentProjectDetail = value;
    },
    setCurrentProjectUsers(state, value) {
      state.currentProjectUsers = value;
    },
    setCurrentOffset(state, value) {
      state.table.offset = value;
    },
    setCurrentFilters(state, value) {
      state.table.filters = value;
    },
  },
  actions: {},
});
