import axios from "axios";
import Settings from "@/config";
import "./types";

const AUTH_URI = Settings.API_URI_AUTH;

/**
 * Login the user
 * @param { LoginPayloadObject } data Payload
 * @return { Promise }
 * @throws { APIError }
 **/
const loginUser = (data) => {
  let config = {
    method: "post",
    url: `${AUTH_URI}/login`,
    withCredentials: true,
    data: data,
  };

  return axios(config);
};

/**
 * Register user
 * @param { RegisterPayloadObject } data Payload
 * @return { Promise }
 * @throws { APIError }
 **/
const registerUser = (data) => {
  let config = {
    method: "post",
    url: `${AUTH_URI}/register`,
    withCredentials: true,
    data: data,
  };

  return axios(config);
};

/**
 * Logout the user
 * @return { Promise }
 * @throws { APIError }
 **/
const logoutUser = () => {
  let config = {
    method: "get",
    url: `${AUTH_URI}/logout`,
    withCredentials: true,
  };

  return axios(config);
};

export { loginUser, registerUser, logoutUser };
