<template>
  <div class="container iq-empty-state">
    <md-icon
      class="md-size-3x"
      :style="error ? 'color: var(--iq-red-500)' : 'color: var(--iq-gray-300)'"
      >{{ icon ? icon : "search_off" }}</md-icon
    >

    <div
      class="md-title"
      :style="error ? 'color: var(--iq-red-500)' : 'color: var(--iq-gray-300)'"
    >
      {{ title ? title : "Nothing found" }}
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  name: "iq-empty-state",
  props: {
    icon: String,
    title: String,
    error: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
</style>
