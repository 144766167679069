<template>
  <div class="iq-container">
    <div class="iq-container--wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "iq-container",
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  &--wrapper {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
</style>
