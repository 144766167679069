import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";

import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

import "./assets/css/main.scss";
import "./components";
import "./plugins/apexcharts";

Vue.config.productionTip = false;

// Remove badInput error
// Reason: Validation has a bug causing a false negative
Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== "production") {
    // Show any error but this one
    if (
      err.message !== "Cannot read properties of undefined (reading 'badInput')"
    ) {
      console.error(err);
    }
  }
};

// Navigation guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.private)) {
    if (!store.getters.userIsLoggedIn) next({ name: "Authorization" });
    else next();
  } else if (to.matched.some((record) => record.meta.public)) {
    if (store.getters.userIsLoggedIn) next({ name: "Dashboard" });
    else next();
  } else {
    next();
  }
});

// Use the UI Framework
Vue.use(VueMaterial);

// Datepicker options
Vue.material.locale.dateFormat = "dd.MM.yyyy";
Vue.material.locale.firstDayOfAWeek = 1;
Vue.material.locale.cancel = "Abbrechen";
Vue.material.locale.ok = "Ok";
Vue.material.locale.days = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];
Vue.material.locale.shortDays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
Vue.material.locale.shorterDays = ["S", "M", "D", "M", "D", "F", "S"];
Vue.material.locale.months = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];
Vue.material.locale.shortMonths = [
  "Jan",
  "Feb",
  "Mär",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dez",
];
Vue.material.locale.shorterMonths = [
  "J",
  "F",
  "M",
  "A",
  "M",
  "J",
  "J",
  "A",
  "S",
  "O",
  "N",
  "D",
];

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
