<template>
  <div>
    <md-avatar class="md-avatar-icon">
      <md-icon class="md-primary">
        <slot />
      </md-icon>
    </md-avatar>
  </div>
</template>

<script>
export default {
  name: "iq-avatar",
};
</script>

<style scoped></style>
