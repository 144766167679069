<template>
  <div class="iq-list-new">
    <slot />
  </div>
</template>

<script>
export default {
  name: "iq-list-wrapper",
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/constants";

.iq-list-new {
  padding: $padding;
}
</style>
