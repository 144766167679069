<template>
  <div class="iq-list-item">
    <slot />
  </div>
</template>

<script>
export default {
  name: "iq-list-item",
};
</script>

<style lang="scss" scoped>
.iq-list {
  &-item {
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid var(--iq-gray-300);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: var(--iq-transition);

    &--icon {
      margin-right: 1rem;
    }

    &--content {
      margin-right: 1rem;
    }

    &--action {
      margin-left: auto;
    }

    &:hover {
      border: 1px solid var(--iq-gray-400);
    }
  }
}

.md-avatar-icon {
  background-color: var(--iq-blue-100) !important;
}
</style>
