const loadingMixin = {
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    toggleLoading() {
      this.loading = !this.loading;
    },
  },
};

export default loadingMixin;
