import Vue from "vue";

import IqMain from "./layout/iq-main";
import IqLoader from "./utilities/iq-loader";
import IqContainer from "./layout/iq-container";
import IqEmptyState from "./utilities/iq-empty-state";
import IqList from "./utilities/iq-list";
import IqListItem from "./utilities/iq-list-item";
import IqToolbar from "./utilities/iq-toolbar";
import IqCard from "./utilities/iq-card";
import IqAvatar from "@/components/utilities/iq-avatar";
import IqStatusPill from "@/components/utilities/iq-status-pill";
import IqListNew from "@/components/utilities/iq-list-new";
import IqListItemNew from "@/components/utilities/iq-list-item-new";

Vue.component("iq-main", IqMain);
Vue.component("iq-loader", IqLoader);
Vue.component("iq-container", IqContainer);
Vue.component("iq-empty-state", IqEmptyState);
Vue.component("iq-list", IqList);
Vue.component("iq-list-item", IqListItem);
Vue.component("iq-toolbar", IqToolbar);
Vue.component("iq-card", IqCard);
Vue.component("iq-avatar", IqAvatar);
Vue.component("iq-status-pill", IqStatusPill);
Vue.component("iq-list-new", IqListNew);
Vue.component("iq-list-item-new", IqListItemNew);
