<template>
  <div class="iq-list">
    <slot />
  </div>
</template>

<script>
export default {
  name: "iq-list",
};
</script>

<style lang="scss" scoped>
.iq-list {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
}
</style>
