<template>
  <div id="app">
    <!-- Header -->
    <div id="header">
      <img
        src="./assets/images/hpms-46x46.svg"
        style="height: 48px; width: 48px"
        alt="logo"
        id="logo"
        @click="redirect('Home')"
      />

      <!-- Menu -->
      <div v-if="this.$store.getters.userIsLoggedIn">
        <md-button
          :class="getCurrentView === 'Dashboard' ? 'md-primary' : ''"
          @click="redirect('Dashboard')"
          >Überblick</md-button
        >
        <md-button
          :class="getCurrentView === 'Projects' ? 'md-primary' : ''"
          @click="redirect('Projects')"
          >Projekte</md-button
        >
        <md-button disabled>Kontakte</md-button>
      </div>

      <!-- Helpers & Profile -->
      <div class="right-drawer" v-if="this.$store.getters.userIsLoggedIn">
        <!-- Notifications & Help -->
        <div class="menu-helpers-drawer">
          <!-- Help -->
          <md-button class="md-icon-button margin-right-1" @click="openHandsOn">
            <md-icon>help</md-icon>
          </md-button>

          <!-- Notifications -->
<!--          <md-badge class="md-accent" md-content="0">-->
<!--            <md-button class="md-icon-button margin-0" disabled>-->
<!--              <md-icon style="color: var(&#45;&#45;iq-gray-400)">notifications</md-icon>-->
<!--            </md-button>-->
<!--          </md-badge>-->
          <md-button class="md-icon-button margin-0" disabled>
              <md-icon style="color: var(--iq-gray-200)">notifications</md-icon>
            </md-button>
        </div>

        <!-- Menu -->
        <md-menu md-align-trigger>
          <md-button
            class="md-icon-button md-primary header-menu-button"
            md-menu-trigger
          >
            <md-icon>person</md-icon>
          </md-button>

          <md-menu-content>
            <md-menu-item @click="redirect('Profile')">
              <md-icon>person</md-icon>
              <span>Dein Profil</span>
            </md-menu-item>

            <md-menu-item @click="redirect('Company')">
              <md-icon>business</md-icon>
              <span>Unternehmen</span>
            </md-menu-item>

            <md-menu-item @click="logout">
              <md-icon>logout</md-icon>
              <span>Abmelden</span>
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>

      <!-- login & register button -->
      <div class="right-drawer" v-if="!this.$store.getters.userIsLoggedIn">
        <md-button class="md-primary" @click="redirect('Authorization')"
          >Anmelden</md-button
        >
        <md-button
          class="md-primary"
          style="background-color: #e9f1ff"
          @click="redirect('Authorization')"
          >Registrieren</md-button
        >
      </div>
    </div>

    <!-- Content -->
    <div id="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import loadingMixin from "@/mixins/loadingMixin";
import { logoutUser } from "@/services/api/authorization";

export default {
  name: "App",
  mixins: [loadingMixin],
  computed: {
    getCurrentView() {
      return this.$route.name;
    },
  },
  methods: {
    openHandsOn() {
      const userRole = this.$store.getters.getUserRole;
      const fileName = userRole === "admin" ? "hands_on_admin.pdf" : "hands_on_mitarbeiter.pdf";

      window.open(`/api/v1/media/download?filename=${fileName}`, '_blank');
    },
    redirect(id) {
      let redirect_id = id;
      if (id === "Home") {
        this.$store.getters.userIsLoggedIn
          ? (redirect_id = "Dashboard")
          : (redirect_id = "Authorization");
      }
      this.$router.push({ name: redirect_id });
    },
    async logout() {
      try {
        await logoutUser();
      } catch (error) {
        console.error(error);
      }
      localStorage.removeItem("access_token");
      this.$store.commit("setUserIsLoggedIn", null);
      await this.$router.push({ name: "Logout" });
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "./assets/css/main";

* {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#app {
  background-color: var(--iq-gray-50);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#header {
  height: 64px;
  background-color: white;
  border-bottom: 1px solid var(--iq-gray-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  & > .right-drawer {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

#logo {
  cursor: pointer;
  transition: filter 250ms ease-in-out;

  &:hover {
    filter: opacity(0.75);
  }
}

#content {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.header-menu-button {
  background-color: var(--iq-blue-100) !important;
}

.menu-helpers-drawer {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: $border-default;
}
</style>
