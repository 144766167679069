<template>
  <md-progress-bar md-mode="indeterminate" v-show="loading"></md-progress-bar>
</template>

<script>
export default {
  name: "iq-loader",
  props: {
    loading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.md-progress-bar {
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
