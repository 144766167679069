import dotenv from "dotenv";

dotenv.config();

const API_URI_BASE = "/api/v1";

export default class Settings {
  // API-Routes
  static API_URI_AUTH = API_URI_BASE + "/authorization";
  static API_URI_CONFIRM = API_URI_BASE + "/confirm";
  static API_URI_USER = API_URI_BASE + "/user";
  static API_URI_COMP = API_URI_BASE + "/company";
  static API_URI_INVITE = API_URI_BASE + "/invite";
  static API_URI_PROJ = API_URI_BASE + "/project";
  static API_URI_CONTACT = API_URI_BASE + "/contact";
  static API_URI_STATISTICS = API_URI_BASE + "/statistics";
  static API_URI_BLOCKING = API_URI_BASE + "/blocking";
  static API_URI_SURVEY = API_URI_BASE + "/survey";
  static API_URI_SUBMISSION = API_URI_BASE + "/submission";
  static API_URI_EXPORT = API_URI_BASE + "/export";
  static API_URI_TOKEN = API_URI_BASE + "/api_token";
  static API_URI_PROJECT_V2 = API_URI_BASE + "/project_new";

  // Additional configs
  static IS_REGISTER_ENABLED = process.env.IS_REGISTER_ENABLED || true;
  static TABLE_CONFIG_STORAGE_KEY = "table-config";

  // Contact persistence
  static LAST_PROJECT_ID = "last-project-id";
  static CONTACTS_CONFIG_STORAGE_KEY = "contact-config";

  // OTHER OPTS
}
