<template>
  <span class="iq-pill" :class="getPillClass(status)" @click="publishClick">{{
    status | getTranslatedStatus
  }}</span>
</template>

<script>
export default {
  name: "iq-status-pill",
  props: {
    status: {
      type: String,
      required: true,
    },
    emitClick: {
      type: Boolean,
    },
  },
  filters: {
    getTranslatedStatus(val) {
      switch (val) {
        case "pending":
          return "Ausstehend";
        case "active":
          return "Aktiv";
        case "inactive":
          return "Inaktiv";
        case "finished":
          return "Abgeschlossen";
        case "backlog":
          return "Nicht bearbeitet";
        case "on_hold":
          return "Pausiert";
        case "in_progress":
          return "In Bearbeitung";
        case "in_review":
          return "In Überprüfung";
        case "retired":
          return "Ausgeschieden";
        default:
          return "Unbekannt";
      }
    },
  },
  methods: {
    getPillClass(val) {
      if (val === "in_progress" || val === "active") return "iq-pill-green";
      else if (val === "inactive" || val === "on_hold" || val === "retired")
        return "iq-pill-red";
      else if (val === "pending" || val === "backlog") return "iq-pill-gray";
      else if (val === "in_review") return "iq-pill-orange";
      else return "iq-pill-blue";
    },
    publishClick() {
      if (this.emitClick) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
