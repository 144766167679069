<template>
  <div class="toolbar">
    <div class="toolbar--left">
      <slot name="left" />
    </div>

    <div class="toolbar--center" v-if="isWithCenter">
      <slot name="center" />
    </div>

    <div class="toolbar--right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ig-toolbar",
  props: {
    isWithCenter: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/constants";

.toolbar {
  padding: $padding;
  border-bottom: $border-default;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: stretch;

  &--left {
    display: flex;
    align-items: center;
    min-width: 25%;
  }

  &--center {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    min-width: 25%;
  }
}
</style>
