<template>
  <div class="iq-list-item-new">
    <div class="iq-list-item-new--title">{{ title }}</div>

    <div class="iq-list-item-new--value">
      <slot />
    </div>

    <div class="iq-list-item-new--divider" />
  </div>
</template>

<script>
export default {
  name: "iq-list-item-new",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.iq-list-item-new {
  &--title {
    font-size: 14px;
    color: var(--iq-blue-500);
  }

  &--value {
    font-size: 18px;
    padding: 4px 0;
  }

  &--divider {
    margin: 0.5rem 0 0.5rem 2rem;
    border-bottom: 1px solid var(--iq-gray-400);
  }
}
</style>
